<template>
    <app-dialog-info
            class="app-dialog-choose-offers"
            v-model="show"
            v-if="show"
            :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Choose offers</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="error" :message="error"></app-error>
                <div class="content">
                    <div v-for="(offer, index) in offers" class="offer-line line" :key="index">
                        <app-checkbox v-model="offer._isSelected">
                            <span :class="{'is-bold': offer._isGoodForDemo}">{{offer.Name}}</span>
                        </app-checkbox>
                    </div>
                </div>
                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="purchaseSelectedOffers">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appCheckbox from '@/components/app-checkbox'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true },
        maxWidth: { type: [Number, String], default: 610 },
        uuid:     { type: String, required: true },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appCheckbox
    },

    data() {
        return {
            offers: [],
            loading: false,
            error: null,
        }
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },
    watch: {
        value (isShowing) {
            if (isShowing) {
                this.loadOffers()
            }
        }
    },

    methods: {
        loadOffers() {
            let params = {
                "SPID": this.current_spid,
                'MtxType': 'CustSubscriberExtension',
                'IncludeInactive': true,
                'SearchOptions.PageNumber': 1,
                'SearchOptions.PageSize': 500
            }

            this.loading = true
            this.$store.dispatch('api_matrixx/GetMtxOffersPaginated', params).then(response => {
                console.log('resp', response)
                this.loading = false
                this.offers = response.MtxOffers.map((offer) => {
                    if (['Setup Services', 'Home to Home Text Asset', 'Home to Home Voice Asset', 'Home Data Asset'].indexOf(offer.Name) >= 0) {
                        offer.Name += '*'
                    }
                    offer._isSelected = false
                    return offer
                })
            }).catch(error => {
                console.error(error)

                this.offers = []

                this.loading = false
            })
        },
        purchaseSelectedOffers() {
            let selectedOffers = this.offers.filter((offer) => {
                return offer._isSelected
            })

            let params = {
                SPID: this.current_spid,
                MtxGroupUUID: this.uuid,
                MtxOffers: selectedOffers,
                ExecuteMode: 1 
            }

            this.error = null
            this.loading = true
            this.$store.dispatch('api_matrixx/MtxGroupPurchaseMtxOffers', params).then(response => {
                console.log('resp', response)
                this.loading = false
                this.$emit('close')
            }).catch(error => {
                console.error(error)

                this.offers = []

                this.loading = false
            })
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-choose-offers {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        .is-bold {
            font-weight: bold;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-choose-offers {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>