<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Subscription Details" @close-sidebar="closeSidebar">
                <sidebar-details :uuid="subUUID" @close-sidebar="closeSidebar"></sidebar-details>
            </app-sidebar-details>
        </template>
        <div id="page-matrixx-customer-group-details" :class="{ 'on-sidebar': on_sidebar }">
            <app-loader v-if="loading" fixed-on="desktop"></app-loader>


            <div class="container">
                <div class="row" v-if="!on_sidebar">
                    <div class="col-12">
                        <router-link class="btn btn-back-to" :to="{ name: 'matrixx-customer-groups' }">Back to Customers</router-link>

                        <div class="header-info">
                            <h1 class="heading">Customer Details</h1>
                        </div>
                    </div>
                </div>

                <div class="row details">
                    <div v-if="details" class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-5'">
                        <app-table-details
                            :rows="rows.group"
                            :data="details"
                            title="Details"
                            class="customer-details"
                        />
                        <div class="buttons-row">
                            <button class="btn btn-primary" @click="showOffersDialog">Add Offers</button>
                            <button class="btn btn-primary" @click="showTopupDialog">Add Topup</button>
                        </div>
                    </div>
                    <div class="col-7">
                        <subscriptions-table :current-page="1" title="Subscriptions" :filter-uuid="details_uuid" @row-click="subscriptionRowClicked" @row-click-mobile="subscriptionRowClicked">
                        </subscriptions-table>

                        <wallet-table :current-page="1" title="Balances" :group-uuid="details_uuid" @row-click="walletRowClicked">
                        </wallet-table>
                    </div>
                </div>
            </div>

            <offers-dialog :uuid="details_uuid" v-model="isShowingOffersDialog" @close="hideOffersDialog">
            </offers-dialog>

            <topup-dialog :uuid="details_uuid" v-model="isShowingTopupDialog" @close="hideTopupDialog">
            </topup-dialog>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import appSidebarDetails from '@/components/app-sidebar-details'
import sidebarDetails from '@/views/matrixx/subscription-details'
import subscriptionsTable from '@/views/matrixx/subscriptions/subscriptions-table'
import walletTable from '@/views/matrixx/pricing/wallet-table'
import offersDialog from '@/views/matrixx/offers/choose-offers-dialog'
import topupDialog from '@/views/matrixx/pricing/choose-topup-dialog'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        baseLayout,
        appLoader,
        appTableDetails,
        appSidebarDetails,
        subscriptionsTable,
        walletTable,
        offersDialog,
        topupDialog,
        sidebarDetails
    },

    data() {
        return {
            details: null,
            isShowingOffersDialog: false,
            isShowingTopupDialog: false,
            rows: {
                group: [
                    { key: 'Name', title: 'Name' },
                    { key: 'UUID', title: 'UUID' },
                    { key: 'MtxObjectID', title: 'Matrix ID' },
                    { key: 'CreatedAtNanos', title: 'Date Created', type: 'datenanos' },
                    { key: 'UpdatedAtNanos', title: 'Last Updated', type: 'datenanos' },
                ],
            },

            loading: false,

            on_sidebar: false,
            subUUID: ''
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadDetails()
        },
        
        walletRowClicked () {

        },
        subscriptionRowClicked (sub) {
            if (this.subUUID === sub.UUID) {
                this.closeSidebar()
            } else {
                this.subUUID = sub.UUID

                this.openSidebar()
            }
        },
        showOffersDialog () {
            this.isShowingOffersDialog = true
        },

        showTopupDialog () {
            this.isShowingTopupDialog = true
        },

        hideOffersDialog () {
            this.isShowingOffersDialog = false
        },

        hideTopupDialog () {
            this.isShowingTopupDialog = false
        },

        loadDetails () {
            if (this.details_uuid) {
                this.loading = true
                this.$store.dispatch('api_matrixx/GetMtxGroupByUUID', { UUID: this.details_uuid, SPID: this.current_spid }).then(resp => {
                    this.details = resp
                    this.loading = false
                }).catch(error => {
                    console.error(error)
                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({ name: 'matrixx-sp-groups', replace: true })
                    }
                })
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.subUUID = null
            this.$emit('close-sidebar')
        }
    },

    watch: {
        details_uuid(val) {
            if (val) {
                this.loadDetails()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),

        details_uuid() {
            return this.uuid || this.$route.params.uuid
        },
    },
}
</script>

<style lang="scss">
#page-matrixx-customer-group-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .details {
        margin-bottom: 30px;

        .col-6,
        .col-12 {
            margin-bottom: 30px;
        }

        &:last-child { margin: 0 };
    }

    .subscriptions-table {
        @include table-cols-width((250px, 120px, 200px, 120px, 85px), true);
        margin-bottom: 24px;
    }
    .wallet-table {
        @include table-cols-width((250px, 120px, 200px, 120px, 85px), true);
        margin-top: 24px;
    }

    .customer-details {
        margin-bottom: 24px;
    }

    .buttons-row {
        display: flex;
        justify-content: space-evenly;
    }
}

@media (max-width: $tablet-size) {
    #page-matrixx-customer-group-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        
        .subscriptions-table {
            @include table-cols-width((150px, 100px, 150px, 100px, 85px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-matrixx-customer-group-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .details {
            margin-bottom: 15px;

            .col-6,
            .col-12,
            .col-mob-12 {
                margin-bottom: 15px;
            }
        }
    }

    .subscriptions-table {
        margin-bottom: 15px;

        @include table-cols-width-mobile((85px, 190px), true);
    }
}
</style>