<template>
    <app-dialog-info
            class="app-dialog-device-delete"
            v-model="show"
            v-if="show"
            :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Apply a topup</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="error" :message="error"></app-error>
                <div class="content">
                    <app-input label="Amount" class="" v-model="topupAmount" type="number" />
                    <app-radio-buttons
                        v-model="selectedBalanceResourceID"
                        :items="balances"

                        key-value="ResourceID"
                        key-label="Name"
                    />
                </div>
                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="topUp">Topup</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'
import appDialogInfo from '@/components/app-dialog-info'
import appInput from '@/components/app-input'
import appRadioButtons from '@/components/app-radio-buttons'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true },
        maxWidth: { type: [Number, String], default: 610 },
        uuid:     { type: String, required: true },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
        appRadioButtons
    },

    data() {
        return {
            selectedBalanceResourceID: null,
            balances: [],
            topupAmount: "50",
            loading: false,
            error: null,
        }
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },
    watch: {
        value (isShowing) {
            if (isShowing) {
                this.loadWallet()
            }
        }
    },

    methods: {
        loadWallet() {
            let params = {
                MtxGroupUUID: this.uuid,
                SPID: this.current_spid,
            }

            this.loading = true
            this.$store.dispatch('api_matrixx/MtxGroupWallet', params).then(response => {
                console.log('resp', response)
                this.loading = false
                this.balances = response.BalanceArray
            }).catch(error => {
                console.error(error)

                this.offers = []

                this.loading = false
            })
        },
        topUp() {
            let params = {
                SPID: this.current_spid,
                MtxGroupUUID: this.uuid,
                Amount: Number(this.topupAmount),
                BalanceID: this.selectedBalanceResourceID,
                ExecuteMode: 1 
            }

            this.error = null
            this.loading = true
            this.$store.dispatch('api_matrixx/MtxGroupRecharge', params).then(response => {
                this.loading = false
                this.$emit('close')
            }).catch(error => {
                console.error(error)

                this.offers = []

                this.loading = false
            })
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-device-delete {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-device-delete {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>