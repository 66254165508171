<template>
    <div>
        <app-table
            class="wallet-table"
            :title="title"
            :cols="cols"
            :rows="wallet"

            :clickable="true"
            :selected-row="rowUuid"

            :sort-value="filter.sort"
            :sort-options="sort"
            @sort-change="onSortChange"

            @row-click="rowClick"
            @row-click-mobile="rowClickMobile"
        />
<!-- 
        <app-pagination
            v-if="pagination.total"

            v-model="pagination.page"

            :total="pagination.total"
            :limit="pagination.limit"
            :page-range="pagination.range"

            @change="onPageChange"
        /> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

export default {
  components: {
        appLoader,
        appTable,
        appPagination,
    },
    props: {
        title: { type: String, default: null },
        groupUuid: { type: String, default: null },
        rowUuid: { type: String, default: null },
        currentPage: {type: Number, default: 1}
    },

    watch: {
        currentPage (val) {
            this.getwallet(val)
        }
    },

    created () {
        this.getwallet(this.currentPage)
    },

    data() {
        return {
            wallet: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'Name', title: 'Name'       },
                { field: 'AvailableAmount', title: 'Amount' },
                { field: 'ThresholdLimit', title: 'Amount' },
            ],

            cols: [
                { key: 'Name',        title: 'Name',         sort: { field: 'Name'   }, highlight: true, },
                { key: 'AvailableAmount',  title: 'Amount',   sort: { field: 'AvailableAmount' } },
                { key: 'ThresholdLimit',  title: 'Threshold',   sort: { field: 'ThresholdLimit' } },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    methods: {
        getwallet(page, filter) {
            console.log('wallet')
            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            console.warn('filter', {...filter})

            let params = {
                "SPID": this.current_spid,
                "MtxGroupUUID": this.groupUuid
            }
            console.warn("SPID", params.SPID)

            const word = filter.word.trim()
            console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            this.$store.dispatch('api_matrixx/MtxGroupWallet', params).then(response => {
                console.log('resp', response)
                this.wallet = response.BalanceArray

                // const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo
                // console.log('DisplayPageList',DisplayPageList,'PageNumber', PageNumber, 'TotalItemCount', TotalItemCount)

                // this.pagination.page = PageNumber
                // this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.wallet = []

                this.loading = false
            })
        },


        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getwallet(this.pagination.page, { word: search.word })
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getwallet()
        },

        onPageChange(page) {
            this.getwallet(page)
        },

        rowClick(ev) {
            this.$emit('row-click', ev)
        },

        rowClickMobile(ev) {
            this.$emit('row-click-mobile', ev)
        }
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',

            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ])
    }
}
</script>

<style lang="scss">
.page-matrixx-sp-groups {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .wallet-table {
        @include table-cols-width((250px, 120px, 200px, 120px, 85px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-matrixx-sp-groups {
        .wallet-table {
            @include table-cols-width((150px, 100px, 150px, 100px, 85px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-matrixx-sp-groups {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .wallet-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>